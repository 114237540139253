import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { CmsContext } from 'logic/context/cms';
import { App } from 'app/app';
import { Seo } from 'components/seo/seo';

const JobsPage: FunctionComponent<any> = ({ data: { contentfulJobsPage } }) => {
  return (
    <CmsContext.Provider value={contentfulJobsPage}>
      <Seo
        title={contentfulJobsPage.metaTags.metatitle}
        description={contentfulJobsPage.metaTags.metadescription}
      />
      <App />
    </CmsContext.Provider>
  );
};

export default JobsPage;

export const query = graphql`
  query JobsPage {
    contentfulJobsPage {
      initiativeApplicationUrl
      metaTags {
        metatitle
        metadescription
      }
      headline
      description {
        description
      }
      jobOffers {
        ... on ContentfulJobOffer {
          title
          type
          location
          linkToPdf
        }
      }
    }
  }
`;
